const Home = () => {
  return (
  <div>
  <h1>Welcome to my website.</h1>
  <img src="https://res.cloudinary.com/dg2t0sykk/image/upload/v1734309665/android-chrome-512x512_a3h4ln.png" alt="selfie" class="img-fluid py-4"/>
  {/* <img src="https://res.cloudinary.com/dg2t0sykk/image/upload/v1734369038/R1-07284-0000_0001_k12z2b.jpg" alt="photo" class="img-fluid py-3"/> */}
  </div>
  );
};

export default Home;